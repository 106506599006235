import React from "react";
import { Link } from "react-router-dom";
import TicTacToeLogo from "./tic-tac-toe.svg";
import NumberGuessLogo from "./uncertainty.svg";
import HoneycombLogo from "./honeycomb.svg";
import WizardLogo from "./pointy-hat.svg";
import MORPGLogo from "./treasure-map.svg";
import SpaceSurvivorsLogo from "./metroid.svg";
import CardMonsters from "./wyvern.svg";
import CogATLogo from "./puzzle.svg";
import ElendiaLogo from "./elendiaLogo.svg";

import "./GamesLandingPage.css";

function GamesLandingPage() {
  const miniGames = [
    {
      name: "Elendia",
      image: ElendiaLogo,
      path: "/elendia",
    },
    {
      name: "CogAT Practice",
      image: CogATLogo,
      path: "/cogat-practice",
    },
    {
      name: "Card Monsters",
      image: CardMonsters,
      path: "/card-monsters",
    },
    {
      name: "Space Survivors",
      image: SpaceSurvivorsLogo,
      path: "/space-survivors",
    },
    {
      name: "MORPG Demo",
      image: MORPGLogo,
      path: "/morpg",
    },
    {
      name: "Tic Tac Toe",
      image: TicTacToeLogo,
      path: "/tic-tac-toe",
    },
    {
      name: "Collect-A-Wizard",
      image: WizardLogo,
      path: "/collect-wizard",
    },
    {
      name: "Random Number Guess",
      image: NumberGuessLogo,
      path: "/number-guess",
    },
    {
      name: "Card/Board Game Demo",
      image: HoneycombLogo,
      path: "/untitled",
    },
  ];

  return (
    <div className="games-landing-page">
      <h1>Choose an App</h1>
      <div className="games-list">
        {miniGames.map((game, index) => (
          <Link
            to={game.path}
            key={index}
            className="game-item">
            <img
              src={game.image}
              alt={game.name}
              className="game-logo"
            />
            <h2>{game.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default GamesLandingPage;
