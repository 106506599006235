import React from "react";
import { useHistory } from "react-router-dom";

function AboutPage() {
  const history = useHistory();

  return (
    <div
      className="container"
      style={{ marginLeft: "15px", marginRight: "15px" }}>
      <div>
        <p>Hello! Thank you for visiting my website.</p>
        <p>
          {" "}
          My name is <a href="mailto:nguy1581@gmail.com">RandomNewb</a> and I
          develop web apps. For full-stack apps, my go-to is React, Express,
          PostgreSQL, and Node. I have dabbled in the Godot engine and Phaser
          for game development. Recently, I have been learning about websockets
          and how to implement them in my projects (see below). I utilize a lot
          of AI (for example, Github Copilot) to help me with my projects. They
          are a great tool to continue one's learning and I highly recommend
          their use.
        </p>
        <p>
          I am a graduate of Prime Digital Academy in Minneapolis, MN. It is a
          great program that provided me with my foundation of programming.
        </p>
      </div>

      <div>
        <p>
          A collection of different projects by me in no particular order! To
          play them, you will need to register a free account.
        </p>
        <ul>
          <li>
            <button onClick={() => history.push("/tic-tac-toe")}>
              {" "}
              Tic Tac Toe
            </button>
            &nbsp; A childhood classic with websockets! Play or spectate your
            friends or random strangers.
          </li>
          <li>
            <button onClick={() => history.push("/collect-wizard")}>
              {" "}
              Collect-A-Wizard
            </button>{" "}
            &nbsp; A Phaser and websockets-enabled game where you play as a
            wizard collecting crystals. Collect 10 before your friends do!
          </li>
          <li>
            <button onClick={() => history.push("/number-guess")}>
              {" "}
              Number Guess
            </button>{" "}
            &nbsp; A number guessing game that uses websockets to allow you to
            play and guess with friends simultaneously. How many tries will it
            take you to guess the number?
          </li>
          <li>
            <button onClick={() => history.push("/untitled")}>
              {" "}
              Board / Card Game Demo
            </button>{" "}
            &nbsp; Currently a client-side-only board/card game built with React
            that is eerily familiar to once-popular game.
          </li>
          <li>
            <button onClick={() => history.push("/morpg")}> MORPG</button>{" "}
            &nbsp; A grid-based multiplayer online role-playing game that uses
            websockets.
          </li>
        </ul>
        <div>
          {" "}
          <p>
            {" "}
            If you are enjoying my work, consider buying me a cup of coffee via
            Ko-Fi!:
            <a
              href="https://ko-fi.com/randomnewb"
              target="_blank">
              <img
                height="36"
                style={{
                  border: "0px",
                  height: "36px",
                  position: "relative",
                  top: "10px",
                }}
                src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
                alt="Buy Me a Coffee at ko-fi.com"
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
