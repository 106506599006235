import "./App.css";
import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Nav from "../Nav/Nav";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

import AboutPage from "../AboutPage/AboutPage";
import NumberGuess from "../NumberGuess/NumberGuess";
import LandingPage from "../LandingPage/LandingPage";
import LoginPage from "../LoginPage/LoginPage";
import RegisterPage from "../RegisterPage/RegisterPage";
import GamesLandingPage from "../GamesLandingPage/GamesLandingPage";

import BinaryChatRoomLobby from "../BinaryChatRoom/BinaryChatRoomLobby";
import BinaryChatRoomGame from "../BinaryChatRoom/BinaryChatRoomGame";
import TicTacToeAgain from "../TicTacToeAgain/TicTacToeAgain";
import Cardosis from "../Cardosis/Cardosis";
import UnnamedGame from "../UnnamedGame/UnnamedGame";
import PhaserGame from "../PhaserGame/PhaserGame";
import CollectWizard from "../CollectWizard/CollectWizard";
import CollisionTest from "../CollisionTest/CollisionTest";
import MORPG from "../MORPG/MORPG";
import SpaceSurvivors from "../SpaceSurvivors/SpaceSurvivors";
import CardMonsters from "../CardMonsters/CardMonsters";
import CogatPracticeTest from "../CogatPractice/CogatPracticeTest.jsx";
import CogatPracticeSettings from "../CogatPractice/CogatPracticeSettings.jsx";
import CogatPracticeLanding from "../CogatPractice/CogatPracticeLanding.jsx";
import Elendia from "../Elendia/Main";

function App() {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);

  useEffect(() => {
    dispatch({ type: "FETCH_USER" });
  }, [dispatch]);

  const ConditionalNav = ({ children }) => (
    <Route
      render={({ location }) => (
        <>
          {location.pathname !== "/morpg" &&
            location.pathname !== "/space-survivors" &&
            location.pathname !== "/card-monsters" && <Nav />}
          {children}
        </>
      )}
    />
  );

  return (
    <Router>
      <div>
        <ConditionalNav>
          <Switch>
            {/* Visiting localhost:3000 will redirect to localhost:3000/home */}
            <Redirect
              exact
              from="/"
              to="/home"
            />

            {/* Visiting localhost:3000/about will show the about page. */}
            <Route
              // shows AboutPage at all times (logged in or not)
              exact
              path="/about">
              <AboutPage />
            </Route>

            <ProtectedRoute
              // logged in shows UserPage else shows LoginPage
              exact
              path="/user">
              <GamesLandingPage />
            </ProtectedRoute>

            <ProtectedRoute
              // logged in shows UserPage else shows LoginPage
              exact
              path="/number-guess">
              <NumberGuess />
            </ProtectedRoute>

            <ProtectedRoute path="/binary">
              <BinaryChatRoomLobby />
            </ProtectedRoute>

            <ProtectedRoute path="/binary/game/:roomId">
              <BinaryChatRoomGame />
            </ProtectedRoute>

            <ProtectedRoute path="/tic-tac-toe">
              <TicTacToeAgain />
            </ProtectedRoute>

            <ProtectedRoute path="/untitled">
              <UnnamedGame />
            </ProtectedRoute>

            <ProtectedRoute path="/cardosis">
              <Cardosis />
            </ProtectedRoute>

            <ProtectedRoute path="/phaser-game">
              <PhaserGame />
            </ProtectedRoute>

            <ProtectedRoute path="/collect-wizard">
              <CollectWizard />
            </ProtectedRoute>

            <ProtectedRoute path="/collision-test">
              <CollisionTest />
            </ProtectedRoute>

            <ProtectedRoute path="/morpg">
              <MORPG />
            </ProtectedRoute>

            <ProtectedRoute path="/space-survivors">
              <SpaceSurvivors />
            </ProtectedRoute>

            <ProtectedRoute path="/card-monsters">
              <CardMonsters />
            </ProtectedRoute>

            <ProtectedRoute path="/cogat-practice">
              <CogatPracticeLanding />
            </ProtectedRoute>

            <ProtectedRoute path="/cogat-practice-test">
              <CogatPracticeTest />
            </ProtectedRoute>

            <ProtectedRoute path="/cogat-practice-settings">
              <CogatPracticeSettings />
            </ProtectedRoute>

            <ProtectedRoute path="/elendia">
              <Elendia />
            </ProtectedRoute>

            <Route
              exact
              path="/login">
              {user.id ? (
                // If the user is already logged in,
                // redirect to the /user page
                <Redirect to="/user" />
              ) : (
                // Otherwise, show the login page
                <LoginPage />
              )}
            </Route>

            <Route
              exact
              path="/registration">
              {user.id ? (
                // If the user is already logged in,
                // redirect them to the /user page
                <Redirect to="/user" />
              ) : (
                // Otherwise, show the registration page
                <RegisterPage />
              )}
            </Route>

            <Route
              exact
              path="/home">
              {user.id ? (
                // If the user is already logged in,
                // redirect them to the /user page
                <Redirect to="/user" />
              ) : (
                // Otherwise, show the Landing page
                <LandingPage />
              )}
            </Route>

            {/* If none of the other routes matched, we will show a 404. */}
            <Route>
              <h1>404</h1>
            </Route>
          </Switch>
        </ConditionalNav>
      </div>
    </Router>
  );
}

export default App;
