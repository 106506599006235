import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./LandingPage.css";

// CUSTOM COMPONENTS
import RegisterForm from "../RegisterForm/RegisterForm";

function LandingPage() {
  const [heading, setHeading] = useState("Welcome");
  const history = useHistory();

  const onLogin = event => {
    history.push("/login");
  };

  return (
    <div className="container">
      <br />
      <center>
        <h1>RandomNewb's Project Showcase</h1>
      </center>

      <p>
        {" "}
        Thank you for visiting this website which serves as a collection of my
        various coding projects and accomplishments.{" "}
      </p>
      <p>
        {" "}
        If you would like to try out some of the apps or projects, feel free to
        register a free account in order to do so.
      </p>
      <p>
        If you experience any bugs or issues while using the website, please
        feel free to reach out to me at{" "}
        <a href="mailto:nguy1581@gmail.com">nguy1581@gmail.com</a>.
      </p>
      <RegisterForm />

      <center>
        <h4>Already a Member?</h4>
        <button
          className="btn btn_sizeSm"
          onClick={onLogin}>
          Login
        </button>
      </center>
    </div>
  );
}

export default LandingPage;
